/**
 * Return  Access Permission of a given route
 * @param {string} slug
 * @param {string} pType
 */

const canUserAccess = (slug, pType) => {
  console.log(slug,pType,"lulu");
  // console.log(slug.path.split('/')[1], pType)
  let x = { '/capsule': '/capsule-master', '/managed-new': '/managed', '/youtube-deleted': '/discrepancy', '/enhancement-video': '/enhancement' }
  if (Object.keys(x).includes('/' + slug.path.split('/')[1])) {
    slug.path = x['/' + slug.path.split('/')[1]]
  }
  const permissionList = computed(() => {
    return getItem("permission")
  });
  if (slug.path == '/')
    return true
  else {
    if (["profile", "change-password", "logout"].includes(slug.path.split("/")[1])) {
      return true;
    }
    const module = permissionList.value && permissionList.value.find(module => module.name === "Add Video");
    if (!module) {
      return false;
    }
    const action = module.module_master.find(action => action.slug === slug.path.split("/")[1]);
    // console.log(action)
    if (!action) {
      return false;
    }
    // console.log(action.permission[pType])
    return action.permission[pType]
  }
};

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

// const ellipsis = (text, size) => {
//   return `${text.split(' ').slice(0, size).join(' ')}...`;
// };

const ellipsis = (text, size) => {
  return `${text.slice(0, size)}...`;
};

/**
 * Return idGenerator of a given id of length
 * @param {array} data
 * @param {number} length
 */

const idGenerator = (data, length = 1) => {
  const arrayData = [];
  data.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

/**
 * Return camelSentence of a given string
 * @param {string} text
 */

const camelSentence = function camelSentence(text) {
  return (" " + text)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
};

function capitalize(val) {
  return val
    .split("_")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
}

import { Excel } from "antd-table-saveas-excel";
import { computed } from "vue";
import { getItem } from "./localStorageControl";
// import { object } from "vue-types";
// import { object } from "vue-types";
function exportExcel(fileName, columns, data, removeCols) {
  let cols = [...columns]; // Use spread operator to create a copy of the columns array
  removeCols?.forEach((x) => {
    cols = cols.filter((item) => item.key !== x); // Filter out columns to be removed
  });
  let today = formatDate(new Date()).split("-").join("");
  var d = new Date();
  let h = d.getHours();
  let m = d.getMinutes();
  let s = d.getSeconds();
  const excel = new Excel(); // Replace 'getExcelObject()' with the appropriate method or library to get the Excel object.
  excel
    .addSheet(`${h}:${m}:${s}_${fileName}`)
    .addColumns(cols)
    .addDataSource(data, {
      str2Percent: true,
    })
    .saveAs(`${fileName.toLowerCase().split(" ").join("_")}_${today}.xlsx`);
}

function getServerImages(image) {
  return process.env.VUE_APP_API_ENDPOINT.split('v1')[0] + image;
}
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
/**
 * Return camelSentence of a given string
 * @param {string} text
 */

const setJson = function setJson(originalJson) {
  const groupedJson = {};
  // Loop through each object in the original JSON and group them based on module_id and module_masters_id
  originalJson.forEach((item) => {
    if (!groupedJson[item[1].module_id]) {
      // If the module_id is not already present in the grouped JSON, create a new object for it
      groupedJson[item[1].module_id] = {
        module_id: item[1].module_id,
        name: item[1].module_name,
        module_master: [],
      };
    }
    const moduleMasterIndex = groupedJson[
      item[1].module_id
    ].module_master.findIndex(
      (master) => master.id === item[1].module_master_id
    );
    if (moduleMasterIndex === -1) {
      // If the module_masters_id is not already present in the module_master array, create a new object for it
      groupedJson[item[1].module_id].module_master.push({
        id: item[1].module_master_id,
        name: item[1].module_master_title,
        slug: item[1].slug,
        permission: {
          add: item[1].add,
          edit: item[1].edit,
          view: item[1].view,
          remove: item[1].remove,
        },
      });
    } else {
      // If the module_masters_id is already present in the module_master array, update its permission object
      groupedJson[item[1].module_id].module_master[
        moduleMasterIndex
      ].permission = {
        add: item[1].add,
        edit: item[1].edit,
        view: item[1].view,
        remove: item[1].remove,
      };
    }
  });

  // Convert the groupedJson object to an array of objects
  return Object.values(groupedJson);
};

export {
  ellipsis,
  idGenerator,
  camelSentence,
  setJson,
  capitalize,
  formatDate,
  exportExcel,
  getServerImages,
  canUserAccess

};
