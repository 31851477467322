const routes = [
  {
    name: "Admin",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/layout/withAdminLayout.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Dashboard.vue"
          ),
        children: [
          {
            path: "",
            name: "dashboard",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/addVideoDashboard.vue"
              ),
          },
          {
            path: "dashboard-new",
            name: "dashboard-new",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/DashboardNew.vue"
              ),
          },
          {
            path: "profile",
            name: "profile",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/profile.vue"
              ),
          },
          {
            path: "change-password",
            name: "change-password",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/ChangePassword.vue"
              ),
          },
          {
            path: "add-video/:slug",
            name: "add-video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/addVideo.vue"
              ),
          },
          {
            path: "add-video/:slug/:id",
            name: "catalog-video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/addVideo.vue"
              ),
          },
          {
            path: "capsule-video/:id",
            name: "CapsuleVideo",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/capsuleVideo.vue"
              ),
          },
          {
            path: "capsule-video",
            name: "Capsule Video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/capsuleVideo.vue"
              ),
          },
          {
            path: "managed-new",
            name: "Managed New",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/managedNew.vue"
              ),
          },
          {
            path: "managed-new/:id",
            name: "ManagedNew",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/managedNew.vue"
              ),
          },
          {
            path: "enhancement-video/:id",
            name: "EnhancementVideo",
            component: () => import("@/view/dashboard/enhancement.vue"),
          },
          {
            path: "enhancement-video",
            name: "enhancementVideo",
            component: () => import("@/view/dashboard/enhancement.vue"),
          },
          {
            path: "sansani-express",
            name: "Sansani Express",
            component: () => import("@/view/dashboard/sansaniExpress.vue"),
          },
          {
            name: "master",
            path: "/:slug",
            children: [
              {
                path: "",
                name: "dynamic-master",
                component: () =>
                  import(
                    /* webpackChunkName: "[request]"  */
                    /* webpackInclude: /\.vue$/ */
                    "../view/masters/dynamic-master.vue"
                  ),
              },
              {
                path: "assign/:id",
                name: "assign-master",
                component: () =>
                  import(
                    /* webpackChunkName: "[request]" */ "../view/masters/assign-master.vue"
                  ),
              },
              {
                path: "create",
                name: "create-master",
                component: () =>
                  import(
                    /* webpackChunkName: "[request]" */ "../view/masters/add-master.vue"
                  ),
              },
              {
                path: "edit/:slug",
                name: "edit-master",
                component: () =>
                  import(
                    /* webpackChunkName: "[request]" */ "../view/masters/add-master.vue"
                  ),
              },
              {
                path: "permission/:id",
                name: "default-permission",
                component: () =>
                  import(
                    /* webpackChunkName: "assign-permission" */ "@/view/dashboard/AssignPermission.vue"
                  ),
              },
            ],
          },
          {
            path: "add-new-video",
            name: "add-new-video",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/AddNewVideos.vue"
              ),
          },

          {
            path: "add-video-list",
            name: "add-video-list",
            component: () =>
              import(
                /* webpackChunkName: "demoNine" */ "@/view/dashboard/AddVideoList.vue"
              ),
          },
        ],
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    name: "Auth",
    path: "/auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/layout/withAuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/view/authentication/SignIn.vue"
          ),
        meta: { requireGuest: true },
      },
      {
        path: "/register",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "@/view/authentication/Signup.vue"
          ),
        meta: { requireGuest: true },
      },
      {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "forgotPassword" */ "@/view/authentication/ForgotPassword.vue"
          ),
        meta: { requireGuest: true },
      },
      {
        path: "/two-factor-authentication",
        name: "two-factor-authentication",
        component: () =>
          import(
            /* webpackChunkName: "twoStepAuthentication" */ "@/view/authentication/TwoFactorAuthentication.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "/two-step-verification",
        name: "two-step-verification",
        component: () =>
          import(
            /* webpackChunkName: "twoStepVerification" */ "@/view/authentication/TwoStepVerification.vue"
          ),
        // meta: { requiresAuth: true },
      },
      {
        path: "/logout",
        name: "logout",
        component: () =>
          import(
            /* webpackChunkName: "logout" */ "@/view/authentication/SignOut.vue"
          ),
        // meta: { requiresAuth: true },
      },
      // {
      //   path: "/home",
      //   name: "home",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "demoNine" */ "@/view/dashboard/Home.vue"
      //     ),
      //   meta: { requiresAuth: true },
      // },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/access-denied",
    name: "Access NotFound",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/view/pages/permissionNot.vue"),
  },
  {
    path: "/page-not-found",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/view/pages/404.vue"),
  },
];

export default routes;
