<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider v-else :theme="{
      rtl,
      topMenu,
      darkMode,
      mainContent,
      ...themeColor,
    }">
    <Suspense>
      <template #default>
        <router-view :key="route.fullPath"></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script setup>
import { ThemeProvider } from "vue3-styled-components";
import { themeColor } from "./config/theme/themeVariables";
import { computed, onMounted } from "vue";
import 'v-calendar/dist/style.css';
import { useRoute, useRouter } from "vue-router";
import { themeStore } from "./stores/theme";
import { setItem } from "./utility/localStorageControl";
const theme = themeStore();
const route = useRoute();
const router = useRouter();
const rtl = computed(() => theme.getRtl);
const isLoading = computed(() => theme.getLoading);
const darkMode = computed(() => theme.getData);
const topMenu = computed(() => theme.getTopMenu);
const mainContent = computed(() => theme.getMain);
onMounted(() => {
  window.addEventListener("message", function (e) {
    if (e.origin !== `${process.env.VUE_APP_YAMS}`) {
      return;
    }
    if (typeof e.data !== "undefined") {
      if (e.data.message === "User has logged out") {
        router.push({ name: "login" });
        console.log("User has logged out");
      } else {
        let key = Object.keys(e.data);
        let value = Object.values(e.data);
        key.forEach((x, n) => {
          setItem(x, value[n]);
        });
      }
    }
  });
});
</script>