import app from "./config/configApp";
import { pinia } from "../src/stores";
import router from "./router";
import "./static/css/style.css";
import "./css/custom-style.scss";
import "@/core/plugins/ant-design";
import "@/core/plugins/fonts";
import "@/core/plugins/maps";
import "@/core/plugins/masonry";
import "@/core/plugins/apexcharts";
import "@/core/plugins/unicons";
import "@/core/components/custom";
import "@/core/components/style";
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import CKEditor from '@ckeditor/ckeditor5-vue';


const i18n = createI18n({
  locale: "en",
  messages: {
    en,
  },
});

app.config.productionTip = false;
app.use(i18n);
app.use(pinia);
app.use(router);
app.use(CKEditor);
app.mount("#app");

app.mixin({
  methods: {
    cancel: function () {
      return history.go(-1);
    },
     
  },
});
