<template>
  <div>
    <div>
      <PageHeaderStyle
        :class="classs"
        :title="title || $slots.title()"
        :subTitle="
          subTitle || this.$slots.subTitle ? this.$slots.subTitle() : null
        "
        :ghost="ghost"
        :extra="this.$slots.buttons && this.$slots.buttons()"
        
      >
  
        <div v-if="routes.length" class="ant_page_bread_group">
          <a-breadcrumb separator=">" v-for="(item, key) in routes" :key="item">
            <a-breadcrumb-item @click="handleclick" :key="key">
              <unicon v-if="key == 0" name="estate"></unicon>
              <a v-if="key !== routes.length - 1" @click="handleclick">{{
                item.breadcrumbName
              }}</a>
              <span v-else>{{ item.breadcrumbName }}</span>
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </PageHeaderStyle>
    </div>
  </div>
</template>
<script>
import { PageHeaderStyle } from "./style";
import VueTypes from "vue-types";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export const PageHeader = defineComponent({
  components: {
    PageHeaderStyle,
  },
  props: {
    title: VueTypes.oneOfType([VueTypes.string, VueTypes.object]),
    subTitle: VueTypes.oneOfType([VueTypes.string, VueTypes.object]),
    bgColor: VueTypes.string,
    classs: VueTypes.string,
    buttons: VueTypes.array,
    ghost: VueTypes.bool,
    breadcrumb: VueTypes.object,
    path: VueTypes.string,
  },
  setup(props) {
    const routes = [];
    const router = useRouter();

    const path = props.path ? props.path !== "" && props.path.split("/") : null;

    path !== null &&
      path.map((p) => {
        p = p.split("-");
        let x = p.map(
          (title) => title.charAt(0).toUpperCase() + title.slice(1)
        );
        p = x.join(" ");
        p == ""
          ? routes.push({ path: "/", breadcrumbName: "Dashboard" })
          : routes.push({ path: p, breadcrumbName: p });
      });

    function handleclick(e) {
      console.log(e);
      !parseInt(e.target.innerText) &&
        (e.target.innerText == "edit"
          ? router.go(0)
          : router.push(
              e.target.innerText == "User"
                ? { name: "user-dashboard" }
                : e.target.innerText == "Dashboard"
                
            ));
    }

    return {
      routes,
      handleclick,
    };
  },
  // render() {
  //   return (
  //     <div>
  //       <div>
  //         <PageHeaderStyle
  //           class={this.class}
  //           title={this.title || this.$slots.title()}
  //           subTitle={
  //             this.subTitle || (this.$slots.subTitle ? this.$slots.subTitle() : null)
  //           }
  //           // breadcrumb={this.routes.length && { routes: this.routes }}
  //           ghost={this.ghost}
  //           extra={this.$slots.buttons && this.$slots.buttons()}
  //         >
  //           {this.routes.length ? (
  //             <a-breadcrumb separator=">">
  //               {this.routes.map((item, key) => {
  //                 return (
  //                   <a-breadcrumb-item onClick={this.handleclick} key={key}>
  //                     {key == 0 && <unicon name="estate"></unicon>}
  //                     {key !== this.routes.length - 1 ? (
  //                       <a onClick={this.handleclick}>{item.breadcrumbName}</a>
  //                     ) : (
  //                       item.breadcrumbName
  //                     )}
  //                   </a-breadcrumb-item>
  //                 );
  //               })}
  //             </a-breadcrumb>
  //           ) : null}
  //         </PageHeaderStyle>
  //       </div>
  //     </div>
  //   );
  // },
});
export default PageHeader;
</script>
Collapse
